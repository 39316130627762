import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const CookiePolicyPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Cookie Policy</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>What Are Cookies?</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Cookies are small text files that are placed on your device when
                you visit a website. They are commonly used to improve website
                functionality, provide a better user experience, and collect
                information about your interactions with the site.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>How We Use Cookies</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                At Travel Lingual, we use cookies for the following purposes:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Essential Cookies: These cookies are necessary for the basic
                  functionality of our website. They enable you to navigate the
                  site and use essential features.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Performance and Analytics Cookies: We use these cookies to
                  collect anonymous data on how visitors use our website. This
                  information helps us improve the website's performance and
                  content.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Functionality Cookies: These cookies enhance your experience
                  by remembering your preferences and choices on the website,
                  such as language settings and user preferences.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Advertising and Third-Party Cookies: We may work with
                  advertising partners who use cookies to display relevant ads
                  to you based on your interests and browsing behavior.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>Managing Cookies</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Most web browsers allow you to control and manage cookies
                through their settings. You can choose to accept, reject, or
                delete cookies. Please note that disabling cookies may affect
                the functionality of our website.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default CookiePolicyPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Cookie Policy | Travel-Lingual"}
      description={
        "Legal Disclaimer. By accessing and using the Travel Lingual website, you agree to comply with and be bound by the following terms and conditions."
      }
      pathname={location.pathname}
    />
  );
};
